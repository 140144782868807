'use client';

import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { usePathname } from 'next/navigation';
import CustomLink from '../CustomLink';
import Icon from '../Icon';
import PublicTalkContent from '../PublicTalkContent';
import Text from '../Text';
import PublicTalkWidgetStyle from './style';
import { pushDataLayer } from '@/helpers';

const PublicTalkWidget = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [hide, setHide] = useState(true);

  const pathname = usePathname();

  useEffect(() => {
    const hidden = Boolean(
      pathname.match('/yansnotes') || pathname.match('/quiz')
    );
    setHide(hidden);

    if (hidden) return;

    const isClosed =
      localStorage.getItem('widget_state') === '0' ||
      Boolean(
        pathname.match(
          /(legend)|(start\-using)|(terms\-of\-service)|(privacy\-policy)|(cookies\-policy)/
        )
      );

    if (window.innerWidth > 800) {
      setIsOpen(!isClosed);
    }
  }, [pathname]);

  if (hide) {
    return <></>;
  }

  const GAIsOpen = (e: SyntheticEvent, isOpen: boolean) => {
    pushDataLayer({
      event: isOpen ? 'yansnotes_widget_open' : 'yansnotes_widget_close',
      element: e.currentTarget,
      target: e.target,
    });
  };

  const GAViewFull = (e: SyntheticEvent) => {
    pushDataLayer({
      event: 'yansnotes_view_full_version',
      element: e.currentTarget,
      target: e.target,
    });
  };

  const onClick = (e: SyntheticEvent) => {
    localStorage.setItem('widget_state', String(Number(!isOpen)));
    setIsOpen(!isOpen);
    GAIsOpen(e, !isOpen);
  };

  return (
    <PublicTalkWidgetStyle
      $isOpen={isOpen}
      onWheelCapture={(e) => e.stopPropagation()}
    >
      <div className={'widget_wrapper'}>
        <div className={'widget_header'}>
          <Text text={'publicTalk'} className={'font-argent'} />
          <CustomLink onClick={GAViewFull} url={'/yansnotes/'}>
            <Text text={'viewFull'} className={'font-avenir-regular'} />
          </CustomLink>
        </div>
        <div className={'widget_content'}>
          <PublicTalkContent mobileOnly fromWidget />
        </div>
      </div>
      <div
        className={`widget_label ${isOpen ? 'isOpen' : ''}`}
        onClick={onClick}
      >
        <Text text={'publicTalk'} className={'font-argent'} />
        <Icon name={'arrow-down'} />
      </div>
    </PublicTalkWidgetStyle>
  );
};

export default PublicTalkWidget;
