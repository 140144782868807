import styled from 'styled-components';

export const MainWrapper = styled.footer`
  --paddingTop: var(--sp10x);
  --paddingBottom: var(--sp5x);
  --paddingLeftAndRight: var(--sp6x);
  --faqWidth: 247px;
  --columnWidth: 200px;
  --leftContainerWidth: 384px;
  --appLinksGap: var(--sp3x);

  background-color: var(--dark100);

  padding: var(--paddingTop) var(--paddingLeftAndRight) var(--paddingBottom);
  position: relative;
  z-index: 11;

  display: flex;
  flex-direction: column;
  width: 100%;

  .socialsWrapper {
    width: 100%;
    display: flex;
    gap: 16px;
    justify-content: flex-end;

    .socialIcon {
      width: var(--sp4x);

      .image-cont {
        --proportion: var(--sp4x);
        height: var(--sp4x);
        width: var(--sp4x);

        img {
          object-fit: contain;
        }
      }
    }
  }

  .appLinksWrapper {
    display: flex;
    width: 100%;
    gap: 16px;

    .appLink {
      display: block;
      border: 1px solid #e3e3e3;
      border-radius: 8px;
      width: fit-content;

      &-image {
        padding: 0;
      }
    }
  }

  @media (max-width: 880px) {
    .appLinksWrapper {
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }

  @media (max-width: 695px) {
    padding: 64px 16px;

    .appLinksWrapper {
      justify-content: flex-start;
    }

    .socialsWrapper {
      justify-content: flex-start;
    }
  }
`;

export const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  gap: 40px;
  padding-bottom: 64px;

  @media (max-width: 695px) {
    padding-bottom: 40px;
    flex-direction: column;
  }
`;

export const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--gray100);
  padding-top: 32px;
  align-items: flex-start;
  gap: 132px;

  .rightCol {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .isoWrapper {
    display: flex;
    align-items: center;
    gap: 8px;

    .image-cont {
      flex-shrink: 0;
    }

    .text {
      color: var(--white1000);
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      max-width: 200px;
    }
  }

  @media (max-width: 695px) {
    flex-direction: column;
    padding-top: 40px;
    gap: 40px;

    .text {
      max-width: unset;
    }

    .rightCol {
      gap: 40px;
    }
  }
`;

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1 0 0;
  min-width: 200px;
  max-width: 384px;

  .logo {
    width: var(--sp13x);
    height: var(--sp7x);
    padding: 0;

    .image-cont {
      img {
        object-fit: contain;
      }
    }
  }

  @media (max-width: 695px) {
    gap: 40px;
    max-width: unset;
    width: 100%;
  }
`;

export const RightWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1 0 0;
  min-width: 376px;
  max-width: 700px;
  gap: 16px;

  .column {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1 0 0;

    .title {
      font-family: var(--urbanist);
      color: var(--white1000);
      font-size: 22px;
      font-weight: 800;
      line-height: 120%;
    }

    .items {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .text {
        font-family: var(--urbanist);
        font-weight: 400;
        line-height: 24px;
        font-size: 16px;
        position: relative;
        width: fit-content;

        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 0;
          height: 1px;
          background-color: var(--white1000);
          transition: var(--trTime);
        }

        @media (hover: hover) {
          &:hover {
            &::before {
              width: 100%;
              left: 0;
            }
          }
        }

        .link {
          color: var(--white1000);
        }
      }
    }

    @media (max-width: 768px) {
      width: auto;
    }
  }

  @media (max-width: 695px) {
    max-width: unset;
    min-width: unset;
    width: 100%;
    flex-wrap: wrap;
    row-gap: 40px;

    .column {
      min-width: min(calc(50vw - 32px), 167px);
    }
  }
`;

export const FAQ = styled.a`
  background-color: #e3e3e333;
  width: 100%;
  padding: var(--sp2x);
  display: flex;
  flex-direction: column;
  gap: var(--sp1-5x);
  height: fit-content;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      .header .arrow {
        transform: translateX(5px);
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    gap: var(--sp1-5x);

    .title {
      flex: 1 0 0;
      color: var(--white1000);
      font-family: var(--avenir);
      font-size: var(--sp2x);
      line-height: var(--sp3x);
    }

    .arrow {
      width: 20px;
      height: 20px;
      font-size: 20px;
      transition: transform var(--trTime) ease-in-out;

      &:before {
        color: var(--coral);
      }
    }
  }

  .question {
    font-size: 14px;
    line-height: 20px;
    font-family: var(--urbanist);
    color: var(--white1000);
    position: relative;
    padding-left: 8px;

    &::before {
      content: '';
      position: absolute;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background-color: #fff;
      left: 0;
      top: 9px;
    }

    &.secondary {
      white-space: nowrap;
      word-wrap: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`;

export const Rights = styled.div`
  margin-top: 24px;

  .text {
    color: var(--white1000);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  @media (max-width: 675px) {
    margin-top: 40px;
  }
`;
