import {
  ChangeEventHandler,
  EventHandler,
  forwardRef,
  SyntheticEvent,
  useState,
} from 'react';
import { Input } from 'antd';
import {
  isEmailInvalid,
  api,
  pushDataLayer,
  useTranslation,
  ACTags,
} from '@/helpers';
import Icon from '@/components/global/Icon';
import Text from '@/components/global/Text';
import { Spinner } from '@/components/global/Spinner';
import SubscribeWidgetStyle from './style';

const SubscribeWidget = forwardRef<HTMLDivElement>(({}, ref) => {
  const translate = useTranslation();
  const [email, setEmail] = useState('');
  const [isError, setIsError] = useState(false);
  const [isSucceed, setIsSucceed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onChange: ChangeEventHandler = (e) => {
    setEmail((e.target as HTMLInputElement).value);
    setIsError(false);
  };

  const submit: EventHandler<SyntheticEvent> = async (e) => {
    pushDataLayer({
      event: 'footer_subscribe_attempt',
      element: e.currentTarget,
      target: e.target,
    });
    if (email.length) {
      const err = isEmailInvalid(email);
      setIsError(err);
      if (!err) {
        setIsLoading(true);
        await api.post('/subscribe', {
          email,
          tag: ACTags.FROM_FOOTER,
        });
        setIsLoading(false);
        setIsSucceed(true);
        pushDataLayer({
          event: 'footer_subscribe_success',
          email,
          target: e.target,
          element: e.currentTarget || e.target,
        });
      }
    }
  };

  return (
    <SubscribeWidgetStyle>
      <div className={'subscribe__title_wrapper'}>
        <Icon name={'bell icon'} className="icon" ref={ref} />
        <Text text={'subscribe'} className={'font-avenir-regular'} />
      </div>

      <Input
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            submit(e);
          }
        }}
        onChange={onChange}
        value={isSucceed ? translate('subscribeText') : email}
        placeholder={translate('enterYourEmail')}
        name="email"
        type="email"
        className={`
          subscribe__input font-avenir-regular 
          ${isError ? 'error' : ''}
          ${isSucceed ? 'succeed' : ''}
        `}
        readOnly={isSucceed}
        suffix={
          !isSucceed ? (
            !isLoading ? (
              <Icon
                name={`arrow-short icon ${
                  email.length && !isError ? 'active' : ''
                }`}
                onClick={submit}
              />
            ) : (
              <Spinner className="spinner" />
            )
          ) : (
            <Icon name={`check icon`} className={`check_icon`} />
          )
        }
      />
      {isError && (
        <Text
          text={'emailInvalid'}
          className={'error_message font-avenir-regular'}
        />
      )}
    </SubscribeWidgetStyle>
  );
});

export default SubscribeWidget;
