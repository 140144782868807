import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/src/components/global/AppFooter/index.tsx");
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/src/components/global/AppHeader/index.tsx");
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/src/components/global/CookieHub/index.js");
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/src/components/global/Notification/index.js");
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/src/components/global/Popup/index.js");
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/src/components/global/PublicTalkWidget/index.tsx");
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/src/components/global/ScrollWrapper/index.tsx");
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/src/components/global/TwitterPixel/index.js");
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/src/context/providers/DataProvider.js");
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/src/context/providers/LanguageProvider.js");
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/src/context/providers/UIProvider.js");
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/src/helpers/_useActiveSection.js");
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/src/helpers/_useElementVisible.ts");
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/src/helpers/_useResizeObserver.js");
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/src/helpers/_useTranslation.js");
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/src/libs/styled-registry.js");
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/src/styles/icons/style.css")