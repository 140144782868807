'use client';

import {
  Fragment,
  useCallback,
  useMemo,
  useRef,
  FC,
  MouseEventHandler,
} from 'react';
import { usePathname } from 'next/navigation';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/all';

//* Helpers
import { config, pushDataLayer, useTranslation } from '@/helpers';

//* Components
import Text from '../Text';
import Image from '../Image';
import CustomLink from '../CustomLink';
import SubscribeWidget from './components/SubscribeWidget';

//* Style
import {
  MainWrapper,
  BottomWrapper,
  LeftWrapper,
  RightWrapper,
  TopWrapper,
  FAQ,
  Rights,
} from './style';
import Icon from '../Icon';

gsap.registerPlugin(ScrollTrigger);

type AppFooterProps = {};

const AppFooter: FC<AppFooterProps> = ({}) => {
  //! Next Navigation
  const pathname = usePathname();

  const t = useTranslation();

  //! Refs
  const ref = useRef(null);
  const bellRef = useRef(null);

  const getFooterRoutes = useCallback(
    (column: string) => {
      return Object.entries(config.routes).map((route, ind) => {
        if (route[1].isFooter === column) {
          return (
            <Text key={ind} className="text">
              <CustomLink url={route[1].path} className="link">
                {t(route[1].name)}
              </CustomLink>
            </Text>
          );
        }
      });
    },
    [config.routes]
  );

  const productItems = useMemo(() => getFooterRoutes('col-1'), []);
  const resourcesItems = useMemo(() => getFooterRoutes('col-2'), []);
  const legalItems = useMemo(() => getFooterRoutes('col-3'), []);

  const socialItems = useMemo(() => {
    return (
      <>
        <CustomLink
          className="socialIcon"
          external
          url={'https://www.linkedin.com/company/owner-one/'}
        >
          <Image src={'/images/svg/linkedin.svg'} alt={'linkedin-in-svg'} />
        </CustomLink>
        <CustomLink
          className="socialIcon"
          external
          url={'https://www.facebook.com/Owner.One.family/'}
        >
          <Image src={'/images/svg/facebook.svg'} alt={'facebook-in-svg'} />
        </CustomLink>
        <CustomLink
          className="socialIcon"
          external
          url={'https://twitter.com/OwnerOneFamily/'}
        >
          <Image src={'/images/svg/twitter.svg'} alt={'x-in-svg'} />
        </CustomLink>
      </>
    );
  }, []);

  const appDownloadItems = useMemo(() => {
    return (
      <Fragment>
        <a
          className={`appLink`}
          target="_blank"
          href="https://apps.apple.com/ru/app/owner-one/id6473855308"
        >
          <Image
            width={180}
            height={52}
            className={`contain appLink-image`}
            src={'/images/svg/footer-app-store.svg'}
          />
        </a>
        <a
          className={`appLink`}
          target="_blank"
          href="https://play.google.com/store/apps/details?id=one.owner.app.android"
        >
          <Image
            width={180}
            height={52}
            className={`contain appLink-image`}
            src={'/images/svg/footer-google-play.svg'}
          />
        </a>
      </Fragment>
    );
  }, []);

  const goToFAQ: MouseEventHandler = (e) => {
    GAIFaqLinkClick(e);
  };

  useGSAP(
    () => {
      let tl: ReturnType<typeof gsap.timeline>;
      ScrollTrigger.create({
        trigger: ref.current,
        start: 'top 100%-=250',
        end: 'bottom 100%-=250',
        onEnter: () => {
          tl = gsap
            .timeline({
              repeat: -1,
              repeatDelay: 5,
            })
            .fromTo(
              bellRef.current,
              {
                rotation: '0deg',
              },
              { rotation: '30deg', duration: 0.2 }
            )
            .fromTo(
              bellRef.current,
              {
                rotation: '30deg',
              },
              { rotation: '-30deg', duration: 0.4 }
            )
            .fromTo(
              bellRef.current,
              {
                rotation: '-30deg',
              },
              { rotation: '30deg', duration: 0.4 }
            )
            .fromTo(
              bellRef.current,
              {
                rotation: '30deg',
              },
              { rotation: '0deg', duration: 0.2 }
            );
        },
        onLeaveBack: () => {
          tl.kill();
          gsap.to(bellRef.current, {
            rotation: '0deg',
            duration: 0.2,
          });
        },
      });
    },
    { dependencies: [ref.current] }
  );

  const GAExternalLinkClick: MouseEventHandler = (e) => {
    if (!(e.target as HTMLElement).closest('A')) return;
    pushDataLayer({
      event: 'footer_external_link_click',
      element: e.currentTarget,
      target: e.target,
    });
  };

  const GAInternalLinkClick: MouseEventHandler = (e) => {
    if ((e.target as HTMLElement).nodeName !== 'A') return;
    pushDataLayer({
      event: 'footer_internal_link_click',
      element: e.currentTarget,
      target: e.target,
    });
  };

  const GAIFaqLinkClick: MouseEventHandler = (e) => {
    e.stopPropagation();
    pushDataLayer({
      event: 'footer_faq_click',
      element: e.currentTarget,
      target: e.target,
    });
  };

  return (
    <MainWrapper
      ref={ref}
      className={pathname.includes('not-found') ? 'disable' : ''}
    >
      <TopWrapper className="topWrapper">
        <LeftWrapper className="leftWrapper" onClick={GAExternalLinkClick}>
          <Image className="logo" src="/images/new-logo.svg" priority />
          <div className="formWrapper">
            <SubscribeWidget ref={bellRef} />
          </div>
          <FAQ onClick={goToFAQ} href="/faq/" className={`faq`}>
            <div className="header">
              <Text className="title uppercase" text="faq" />
              <Icon name="arrow-short" className="arrow" />
            </div>
            <Text className="question main">
              How to become an Owner.One user?
            </Text>
            <Text className="question secondary">
              Why am I on the Waitlist and can’t subscribe immediately?
            </Text>
          </FAQ>
        </LeftWrapper>
        <RightWrapper className="rightWrapper" onClick={GAInternalLinkClick}>
          <div className="column">
            <Text className="title capitalize" text={`product`} />
            <div className="items">{productItems}</div>
          </div>

          <div className="column">
            <Text className="title capitalize" text={`recourses`} />
            <div className="items">{resourcesItems}</div>
          </div>

          <div className="column">
            <Text className="title capitalize" text={`legal`} />
            <div className="items">{legalItems}</div>
          </div>
        </RightWrapper>
      </TopWrapper>
      <BottomWrapper className="bottomWrapper">
        <CustomLink url="/about-iso-14721/" className="isoWrapper">
          <Image
            height={100}
            width={100}
            className="iso_icon"
            alt="ISO 14721"
            src={'/images/svg/iso.svg'}
          />
          <Text className="text">{t('isoCompliant')}</Text>
        </CustomLink>
        <div className="rightCol">
          <div className={`socialsWrapper`}>{socialItems}</div>
          <div className={`appLinksWrapper`}>{appDownloadItems}</div>
        </div>
      </BottomWrapper>
      <Rights>
        <Text className="text">
          © {new Date().getFullYear()} Owner.One, {t('allRightsReserved')}
        </Text>
      </Rights>
    </MainWrapper>
  );
};

export default AppFooter;
