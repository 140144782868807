import styled from 'styled-components';

const HeaderStyle = styled.header`
  --headerPadding: var(--sp3x) var(--sp6x);
  --logoWidth: 130px;
  --logoHeight: var(--sp5x);

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--headerHeight);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--headerPadding);
  z-index: 22;
  background: linear-gradient(0deg, transparent -6.19%, var(--dark100) 100%);
  transition: background 1s ease-in-out;

  &.dark {
    background-color: var(--dark100);
    transition: background 1s ease-in-out;

    .menus-wrap {
      .navigation-link {
        .comp-text {
          color: var(--white1000);
        }
      }
    }

    .contact-us {
      .comp-text {
        color: var(--white1000);
      }
    }

    .selected-lang-wrap {
      .comp-text {
        color: var(--white1000);
      }

      .language-dropdown-icon {
        color: var(--white1000);
      }
    }
  }

  &.burger-open {
    background-color: var(--dark100);
    transition: background var(--trTime) ease-in-out;
  }

  .menus-wrap {
    display: flex;
    gap: var(--sp3x);
    opacity: 0;

    .navigation-link {
      position: relative;
      overflow: hidden;
      padding-bottom: var(--sp0-5x);
      margin-top: var(--sp1x);

      .text {
        display: block;
        transition: 0.2s;
        font-size: var(--sp1-5x);
        line-height: 20px;
        letter-spacing: 0.96px;
        font-family: var(--avenir);
      }

      @media (hover: hover) {
        &:hover {
          &::before {
            width: 100%;
            left: 0;
          }
        }
      }

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 1px;
        background-color: var(--white1000);
        transition: var(--trTime);
      }

      &::after {
        opacity: 0;
      }

      &.active {
        .text {
          font-weight: 700;
        }

        &::before {
          animation: converge var(--trTime) ease-in forwards;
        }

        @keyframes converge {
          90% {
            background-color: #cd7764;
            width: 6px;
            left: calc(50% - 3px);
          }
          100% {
            opacity: 0;
            pointer-events: none;
          }
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: calc(50% - 3px);
          width: 6px;
          height: 1px;
          background-color: #cd7764;
          border-radius: 50%;
          animation: appear var(--trTime) ease-in forwards;
          opacity: 1;
        }

        @keyframes appear {
          0% {
            height: 1px;
          }
          100% {
            height: 6px;
          }
        }
      }

      &:last-child {
        margin-right: unset;
      }
    }
  }

  .contacts-wrap {
    display: flex;
    align-items: center;
    opacity: 0;

    .contact-us {
      position: relative;
      overflow: hidden;

      .text {
        padding: var(--sp1x) 0;
        transition: 0.2s;
      }

      @media (hover: hover) {
        &:hover {
          &::after {
            width: 100%;
          }
        }
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 1px;
        background-color: var(--royalBlue1000);
        transition: var(--trTime);
      }

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: transparent;
      }

      &.active {
        pointer-events: none;

        &::before {
          background-color: var(--royalBlue1000);
        }
      }
    }

    .buttons-wrap {
      display: flex;
      align-items: center;
      width: 130px;
      justify-content: flex-end;
    }

    .join-wait-list {
      .link-item {
        height: 40px;
        font-size: 12px;
        font-weight: 450;
        line-height: 20px;
        letter-spacing: 0.96px;
        text-transform: uppercase;
      }
    }
  }

  .logo-link {
    width: var(--logoWidth);
    cursor: pointer;
    opacity: 0;

    .logo {
      width: 74px;
      height: var(--logoHeight);
      object-fit: cover;
    }
  }

  .burger-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 7px;
    align-items: flex-start;
    width: 130px;
    height: var(--sp5x);
    cursor: pointer;

    .line {
      background-color: var(--white1000);
      height: 2px;
      width: 24px;
      border-radius: 100px;
      transition: transform 0.4s ease-in-out;
    }

    &.active {
      .line1 {
        transform: rotate(45deg) translateX(25%);
      }

      .line2 {
        transform: rotate(-45deg) translateX(25%);
      }
    }
  }

  .burger-wrap {
    display: none;
  }

  //! 768
  @media only screen and (max-width: 1023px) and (min-width: ${(props) =>
      props.theme.mediaQuery.tabletSizeS}) {
    .logo-link {
      margin: 0 auto;
      width: fit-content;
    }

    .menus-wrap {
      display: none !important;
    }

    .burger-wrap {
      display: flex;
    }
  }

  //! Mobile
  @media only screen and (max-width: ${(props) =>
      props.theme.mediaQuery.tabletSizeSMin}) {
    --headerPadding: var(--sp3x);

    justify-content: space-evenly;
    align-items: center;

    .logo-link {
      margin: 0 auto;
      width: fit-content;
    }

    .menus-wrap {
      display: none !important;
    }

    .contacts-wrap .buttons-wrap {
      width: 24px;
    }

    .buttons-wrap .primary {
      display: none;
    }

    .burger-wrap {
      width: 24px;
      display: flex;
    }
  }
`;

export default HeaderStyle;
