'use client';
import { memo, useMemo, useEffect, useRef, useCallback, FC } from 'react';
import { usePathname, useParams } from 'next/navigation';
import gsap from 'gsap';

//* HOC's
import { withUIContext } from '@/context';

//* Style
import HeaderStyle from './style';

//* Config
import config from '@/helpers/_config';

//* Components
import Text from '@/components/global/Text';
import BurgerMenu from './BurgerMenu';
import CustomLink from '@/components/global/CustomLink';
import Button from '@/components/global/Button';
import Image from '@/components/global/Image';

type HeaderProps = {
  winWidth: number;
  isBurgerMenuOpen: boolean;
  toggleBurgerMenu: () => void;
  closeBurgerMenu: () => void;
};

const AppHeader: FC<HeaderProps> = memo(
  ({ winWidth, toggleBurgerMenu, isBurgerMenuOpen, closeBurgerMenu }) => {
    //! Next Navigation
    const pathname = usePathname();
    const params = useParams();
    //! Refs
    const logoRef = useRef<HTMLAnchorElement>(null);
    const listItemsRef = useRef<HTMLDivElement>(null);
    const contactsWrapRef = useRef<HTMLDivElement>(null);

    //! Checks
    const headerMenus = useMemo(
      () => Object.values(config.routes).filter((item) => item.isHeader),
      [pathname]
    );

    //! Store Header Menu
    const storeHeaderMenus = useMemo(() => {
      return headerMenus.map((item, index) => {
        return (
          <CustomLink
            className={`navigation-link ${
              pathname.includes(item.path) ? 'active' : ''
            }`}
            key={index}
            url={item.path}
          >
            <Text
              className={'p p6 font-avenir-regular white-1000 uppercase text'}
            >
              {item.name}
            </Text>
          </CustomLink>
        );
      });
    }, [headerMenus]);

    //! Handle Logo Click
    const handleLogoClick = useCallback(() => {
      closeBurgerMenu();
    }, []);

    useEffect(() => {
      closeBurgerMenu();
    }, [winWidth, pathname, params]);

    useEffect(() => {
      logoRef.current && gsap.to(logoRef.current, { opacity: 1, delay: 0.5 });
      listItemsRef.current &&
        gsap.to(listItemsRef.current, { opacity: 1, delay: 0.5 });
      contactsWrapRef.current &&
        gsap.to(contactsWrapRef.current, { opacity: 1, delay: 0.5 });
    }, [winWidth]);

    return (
      <>
        <BurgerMenu
          headerMenus={headerMenus}
          isBurgerMenuOpen={isBurgerMenuOpen}
        />

        <HeaderStyle
          className={`header dark ${isBurgerMenuOpen ? 'burger-open' : ''}`}
        >
          <div
            className={`burger-wrap ${isBurgerMenuOpen ? 'active' : ''}`}
            onClick={() => toggleBurgerMenu()}
          >
            <span className="line line1" />
            <span className="line line2" />
          </div>
          <a
            ref={logoRef}
            href="/"
            onClick={handleLogoClick}
            className={`logo-link ${winWidth < 1280 ? 'mobile' : ''}`}
          >
            <Image priority className={'logo'} src={'/images/new-logo.svg'} />
          </a>
          <div className="menus-wrap" ref={listItemsRef}>
            {storeHeaderMenus}
          </div>
          <div className="contacts-wrap" ref={contactsWrapRef}>
            <div className="buttons-wrap">
              <Button
                external
                url={'https://personal-account.owner.one/en/signin/'}
                text="CLIENTS ONLY"
                className={'join-wait-list primary inverse'}
              />
            </div>
            {/* //! ALSO WHEN UNCOMMENT LANGUAGE SWITCHER FIX BUTTON MARGIN */}
            {/* <LanguageSwitcher /> */}
          </div>
        </HeaderStyle>
      </>
    );
  }
);

export default withUIContext<HeaderProps>(AppHeader, [
  'winWidth',
  'toggleBurgerMenu',
  'isBurgerMenuOpen',
  'closeBurgerMenu',
  'openPopup',
]);
